import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useCheckLimit, usePlatforms, usePlatformSelector } from "hooks/dev";
import { Layout } from "components/common/Layout";
import { Button, Container, ModalV2 } from "components/common";
import {
  EmptyPlatform,
  Platform,
  SkeletonPlatform,
} from "components/Platforms/Platform";
import { AuthPlatform } from "components/Platforms/AuthPlatform";
import {
  useGetReposts,
  useAddRepost,
  useDeleteRepost,
  useOnPauseRepost,
} from "hooks/business";
import { ArrowRight } from "components/icons";
import { useLng } from "hooks/dev";

const AddRepost = () => {
  const { t } = useTranslation();
  const { onAddRepost } = useAddRepost();
  const { platforms, isLoading } = usePlatforms({ checkAuth: false });
  const { selectedPlatforms, onChangeSelectedPlatforms } =
    usePlatformSelector();
  const [from, setFrom] = useState(null);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const { executeFnWithCheckLimit } = useCheckLimit("REPOST");

  const isDisabled = !from || !Object.values(selectedPlatforms).some(Boolean);

  const onSubmit = () => {
    if (isDisabled) return;
    onAddRepost({
      from: from.id,
      to: Object.entries(selectedPlatforms)
        .filter(([_, isSelected]) => isSelected)
        .map(([id]) => id),
    });
  };

  return (
    <>
      <div className="border border-main w-full p-[20px_10px] md:p-[20px]">
        <div className="flex items-center gap-4">
          {from ? (
            <Platform
              {...from}
              isSelected
              onClick={() => setIsOpenModal(true)}
            />
          ) : (
            <EmptyPlatform onClick={() => setIsOpenModal(true)} />
          )}
          <ArrowRight className="size-6 min-w-6" />
          <div>
            {isLoading ? (
              <div className="flex gap-3 py-2">
                <SkeletonPlatform />
                <SkeletonPlatform />
                <SkeletonPlatform />
              </div>
            ) : (
              <div className="flex gap-3 flex-wrap">
                {(platforms || [])
                  .filter(
                    (platform) => from === null || platform.id !== from.id
                  )
                  .map((platform) => (
                    <Platform
                      key={platform.id}
                      onClick={() => onChangeSelectedPlatforms(platform.id)}
                      isSelected={selectedPlatforms?.[platform.id]}
                      {...platform}
                    />
                  ))}
                <AuthPlatform />
              </div>
            )}
          </div>
        </div>
        <div className="mt-6 w-full flex gap-2 justify-end">
          <Button
            disabled={isDisabled}
            onClick={() => executeFnWithCheckLimit(() => onSubmit())}
          >
            {t("add-auto-repost", "Add Auto Repost")}
          </Button>
        </div>
      </div>
      <ModalV2
        title={t("repost-from", "Repost From")}
        isOpen={isOpenModal}
        onClose={() => setIsOpenModal(false)}
      >
        <div>
          <div className="mb-2">
            {t("repost-select-platform-from", "Select platform to repost from")}
            :
          </div>
          <div className="flex gap-3 flex-wrap py-2">
            {(platforms || [])
              .filter(
                (platform) =>
                  platform.platform === "TikTok" ||
                  platform.platform === "Instagram"
              )
              .map((platform) => (
                <Platform
                  isDisabled={
                    platform.platform !== "TikTok" &&
                    platform.platform !== "Instagram"
                  }
                  key={platform.id}
                  onClick={() => {
                    setFrom(platform);
                    setIsOpenModal(false);
                  }}
                  {...platform}
                />
              ))}
            <AuthPlatform
              filter={(platform) =>
                platform === "TikTok" || platform === "Instagram"
              }
            />
          </div>
          <div className="text-xs text-gray-400 italic mt-2">
            {t(
              "only-repost-from",
              "* Only reposts from TikTok and Instagram are currently available. Other platforms will be supported soon"
            )}
          </div>
        </div>
      </ModalV2>
    </>
  );
};

export const Repost = () => {
  const { t } = useTranslation();
  const { data } = useGetReposts();
  const { locale } = useLng();
  const { onDeleteRepost } = useDeleteRepost();
  const { onPauseRepost } = useOnPauseRepost();

  return (
    <Layout>
      <Container>
        <div className="pt-[20px] w-full h-full flex flex-col">
          <div className="text-3xl text-main mb-4 font-bold">
            {t("reposts", { defaultValue: "Reposts" })}
          </div>
          <AddRepost />
          <div className="flex flex-col gap-2 mt-8">
            {(data || []).map((el, idx) => (
              <div
                key={idx}
                className="border border-main w-full p-[20px_10px] md:p-[20px]"
              >
                <div className="text-sm mb-2">
                  <b>
                    {t("created", "Created")}
                    {`: `}
                  </b>
                  {new Date(el.date).toLocaleDateString(locale, {
                    weekday: "long",
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                  })}
                </div>
                <div className="flex items-center gap-4">
                  <Platform {...el.from} />
                  <ArrowRight className="size-6 min-w-6" />
                  <div>
                    <div className="flex gap-3 flex-wrap">
                      {(el.to || []).map((platform) => (
                        <Platform key={platform.id} {...platform} />
                      ))}
                    </div>
                  </div>
                </div>
                <div className="flex justify-end mt-4 gap-2">
                  <div
                    onClick={() =>
                      onPauseRepost({ id: el.id, pause: !el.isPaused })
                    }
                    className={`text-xs border rounded-md px-2 py-1 cursor-pointer flex items-center gap-1 ${
                      el.isPaused
                        ? `border-green-400 text-green-400 hover:bg-green-400 hover:text-third`
                        : `border-gray-400 text-gray-400 hover:bg-gray-400 hover:text-third`
                    }`}
                  >
                    {el.isPaused ? (
                      <svg
                        className="w-3 h-3"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                      >
                        <path d="M8 5v14l11-7z" />
                      </svg>
                    ) : (
                      <svg
                        className="w-3 h-3"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                      >
                        <path d="M6 19h4V5H6v14zm8-14v14h4V5h-4z" />
                      </svg>
                    )}
                    {el.isPaused ? t("resume", "Resume") : t("pause", "Pause")}
                  </div>
                  <div
                    onClick={() => onDeleteRepost(el.id)}
                    className="text-xs border border-error text-error rounded-md px-2 py-1 cursor-pointer hover:bg-error hover:text-third"
                  >
                    {t("cancel-repost", "Cancel Repost")}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </Container>
    </Layout>
  );
};
