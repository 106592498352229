import { useTranslation } from "react-i18next";
import { Checkbox, DateInput } from "./common";

export function Schedule({ values, onChange }) {
  const { t } = useTranslation();

  const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const today = new Date().toISOString().split("T")[0];
  const now = new Date().toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
    timeZone: userTimezone,
  });

  return (
    <div>
      <Checkbox
        value={values.isScheduled}
        onChange={() => onChange("isScheduled")(!values.isScheduled)}
        label={t("schedule", "Schedule")}
        className="w-fit"
      />
      {values.isScheduled && (
        <div className="mt-2">
          <div className="flex items-center gap-4">
            <DateInput
              label={t("select-date", "Select Date")}
              value={values.date}
              onChange={onChange("date")}
              id="schedule-date"
              type="date"
              min={today}
            />
            <DateInput
              label={t("select-time", "Select Time")}
              value={values.time}
              onChange={onChange("time")}
              id="schedule-time"
              type="time"
              min={values.date === today ? now : undefined}
              className="min-w-[120px]"
            />
          </div>
          <div className="text-gray-400 text-xs mt-1">
            {t("time-zone-tip", {
              defaultValue: "* Time shown in {{timezone}}",
              timezone: userTimezone,
            })}
          </div>
        </div>
      )}
    </div>
  );
}
