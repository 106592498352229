import { useLng, usePaywall, useUser } from "hooks/dev";
import { useTranslation } from "react-i18next";
import { Link, NavLink } from "react-router-dom";
import {
  Hand,
  ListCheck,
  Logout,
  Plus,
  Settings,
  Globe,
  Accounts,
  Repost,
  Coins,
} from "components/icons";
import { Logo } from "components/icons/Logo";
import { LocaleModal } from "components/Modal/LocaleModal";

const list = [
  {
    title: "History",
    i18nKey: "history",
    href: "/",
    icon: ListCheck,
  },
  {
    title: "Post",
    i18nKey: "post",
    href: "/post",
    icon: Plus,
  },
  {
    title: "Repost",
    i18nKey: "repost",
    href: "/repost",
    icon: Repost,
  },
  {
    title: "Accounts",
    i18nKey: "accounts",
    href: "/accounts",
    icon: Accounts,
  },
  {
    title: "Settings",
    i18nKey: "settings",
    href: "/settings",
    icon: Settings,
  },
];

export const Menu = () => {
  const { t } = useTranslation();
  const { userInfo } = useUser();
  const { language } = useLng();
  const { isActive } = userInfo || {};
  const { showPaywall } = usePaywall();

  return (
    <div className="sticky top-0 min-h-[calc(100dvh)] max-h-[calc(100dvh)] min-w-[270px] max-w-[270px] box-border p-[10px]">
      <div className="flex h-full flex-col bg-main rounded-md px-[20px] py-[12px]">
        <div>
          <div className="pt-4 pb-6">
            <a className="text-xl font-bold" href="https://taisly.com">
              <Logo className="w-[100px] h-fit text-third" />
            </a>
          </div>
          <div className="text-fourth text-sm">
            {t("welcome", { defaultValue: "Welcome" })},
          </div>
          <div className="text-third font-bold text-lg overflow-hidden overflow-ellipsis">
            {userInfo.email}
          </div>
        </div>
        <div className="flex flex-col gap-4 mt-6">
          {list.map((link) => (
            <NavLink to={link.href} key={link.href}>
              {({ isActive }) => (
                <div
                  className={[isActive ? "text-acent" : "text-third"]
                    .filter(Boolean)
                    .join(" ")}
                >
                  <div className="flex items-center gap-2">
                    <link.icon
                      className={["w-4 h-4"].filter(Boolean).join(" ")}
                    />
                    <div
                      className={["text-lg", isActive ? "font-semibold" : ""]
                        .filter(Boolean)
                        .join(" ")}
                    >
                      {t(link.i18nKey, { defaultValue: link.title })}
                    </div>
                  </div>
                </div>
              )}
            </NavLink>
          ))}
        </div>
        <div className="mt-auto flex flex-col gap-2">
          <LocaleModal>
            <div className="text-lg text-third flex gap-2 items-center cursor-pointer">
              <Globe className="w-4 h-4" /> {language}
            </div>
          </LocaleModal>
          {!isActive ? (
            <div
              onClick={() => showPaywall()}
              className="text-lg text-third flex gap-2 items-center cursor-pointer"
            >
              <Coins className="w-4 h-4" />
              <span>{t("subscribe", "Subscribe")}</span>
            </div>
          ) : null}
          <a
            href="mailto:taislygroup@gmail.com"
            className="text-lg text-third flex gap-2 items-center cursor-pointer"
          >
            <Hand className="w-4 h-4" />
            <div className="text-lg">{t("help", { defaultValue: "Help" })}</div>
          </a>

          {/* <a
            href="https://taisly.com/privacy-policy"
            className="text-lg text-third flex gap-2 items-center cursor-pointer"
          >
            <span>
              {t("privacy-policy", { defaultValue: "Privacy Policy" })}
            </span>
          </a> */}
          <Link
            className="flex items-center gap-2 text-third cursor-pointer"
            to="/logout"
          >
            <Logout className="w-4 h-4" />
            <div className="text-lg">
              {t("logout", { defaultValue: "Logout" })}
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export const MobileMenu = () => {
  return (
    <div className="w-full px-2 pb-2">
      <div className="grid grid-cols-5 bg-main rounded-md">
        {list.map((link) => (
          <NavLink to={link.href} key={link.href}>
            {({ isActive }) => (
              <div className="flex justify-center p-4">
                <link.icon
                  className={[
                    "min-w-5 size-5",
                    isActive ? "text-acent" : "text-third",
                  ]
                    .filter(Boolean)
                    .join(" ")}
                />
              </div>
            )}
          </NavLink>
        ))}
      </div>
    </div>
  );
};
