import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useConfirm } from "hooks/dev/useConfirm";

const getRepostsFn = async () =>
  fetch("/api/private/reposts")
    .then((res) => res.json())
    .then((res) => {
      if (res.success) return res.data;
      return [];
    });

export const useGetReposts = () =>
  useQuery(["GET_REPOSTS"], getRepostsFn, { staleTime: Infinity });

const deleteRepostFn = async (body) =>
  fetch("/api/private/repost/delete", {
    method: "POST",
    body: JSON.stringify(body),
    headers: {
      "Content-Type": "application/json",
    },
  }).then((res) => res.json());

const pauseRepostFn = async (body) =>
  fetch("/api/private/repost/pause", {
    method: "POST",
    body: JSON.stringify(body),
    headers: {
      "Content-Type": "application/json",
    },
  }).then((res) => res.json());

export const useDeleteRepost = () => {
  const queryClient = useQueryClient();
  const confirm = useConfirm();
  const { t } = useTranslation();

  const { mutate } = useMutation({
    mutationKey: ["DELETE_REPOST"],
    mutationFn: deleteRepostFn,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["GET_REPOSTS"] });
      toast.success(t("success", "Success"));
    },
    onError: () => {
      toast.error(t("failed", "Failed"));
    },
  });

  const onDeleteRepost = (id) => {
    confirm({
      title: t("cancel-auto-repost", "Cancel Auto Repost"),
      // description: t("cancel-auto-repost-description", ""),
      onSubmit: () => mutate({ id }),
    });
  };

  return { onDeleteRepost };
};

export const useOnPauseRepost = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const { mutate: onPauseRepost } = useMutation({
    mutationKey: ["PAUSE_REPOST"],
    mutationFn: pauseRepostFn,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["GET_REPOSTS"] });
      toast.success(t("success", "Success"));
    },
    onError: () => {
      toast.error(t("failed", "Failed"));
    },
  });

  return { onPauseRepost };
};

const addRepostFn = async (body) =>
  fetch("/api/private/repost/add", {
    method: "POST",
    body: JSON.stringify(body),
    headers: {
      "Content-Type": "application/json",
    },
  }).then((res) => res.json());

export const useAddRepost = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const { mutate } = useMutation({
    mutationKey: ["ADD_REPOST"],
    mutationFn: addRepostFn,
    onSuccess: (data) => {
      if (data.success) {
        queryClient.invalidateQueries({ queryKey: ["GET_REPOSTS"] });
        toast.success(t("success", "Success"));
      } else {
        toast.error(t(data.message || "failed"));
      }
    },
    onError: () => {
      toast.error(t("failed", "Failed"));
    },
  });

  return { onAddRepost: mutate };
};
