import { useEffect, useState } from "react";
import { createPortal } from "react-dom";

export const Tooltip = ({ text, parentRef }) => {
  const [position, setPosition] = useState({ top: 0, left: 0 });

  useEffect(() => {
    if (!parentRef.current) return;

    const updatePosition = () => {
      const rect = parentRef.current.getBoundingClientRect();
      setPosition({
        top: rect.bottom + window.scrollY + 2,
        left: rect.left + window.scrollX + rect.width / 2,
      });
    };

    updatePosition();
    window.addEventListener("scroll", updatePosition);
    window.addEventListener("resize", updatePosition);

    return () => {
      window.removeEventListener("scroll", updatePosition);
      window.removeEventListener("resize", updatePosition);
    };
  }, [parentRef]);

  return createPortal(
    <div
      className="hidden md:block fixed transform -translate-x-1/2 bg-gray-800 text-white text-sm px-2 py-1 rounded whitespace-nowrap z-[1000]"
      style={{
        top: `${position.top}px`,
        left: `${position.left}px`,
      }}
    >
      {text}
    </div>,
    document.body
  );
};
