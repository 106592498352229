import { PlatformIcon, Plus, Check } from "../icons";
import { useRef, useState } from "react";
import { Tooltip } from "../common/Tooltip";

export const SkeletonPlatform = () => {
  return (
    <div className="relative w-[50px] h-[50px] min-w-[50px] min-h-[50px] md:w-[60px] md:h-[60px] rounded-full cursor-not-allowed border-2 border-gray-200 bg-white flex justify-center items-center">
      <div className="w-6 h-6 rounded-full animate-spin border-4 border-solid border-gray-500 border-t-transparent" />
    </div>
  );
};

export const Platform = (props) => {
  const {
    status,
    platform,
    displayName,
    username,
    usernameImage,
    isSelected,
    onClick = () => {},
    isDisabled = false,
  } = props;

  const [showTooltip, setShowTooltip] = useState(false);
  const platformRef = useRef(null);

  const isConnected = status === "CONNECTED";
  const isExpired = status === "EXPIRED";

  const borderColor = isSelected
    ? "border-gray-700"
    : isConnected
    ? "border-gray-200"
    : "border-red-500";

  const icon = isSelected ? (
    <Check className="absolute -top-2 -right-2 w-6 h-6 md:w-8 md:h-8 text-white bg-gray-700 rounded-full p-1 md:p-1.5" />
  ) : isExpired ? (
    <div className="absolute -top-2 -right-2 w-6 h-6 md:w-8 md:h-8 text-white bg-red-500 rounded-full p-1 md:p-1.5 flex justify-center items-center">
      !
    </div>
  ) : null;

  const usernameDisplay = usernameImage ? (
    <img
      className="rounded-full border-2 border-third w-full h-full"
      src={
        /^(https?:\/\/)/.test(usernameImage)
          ? usernameImage
          : `/uploads/platform-images/${usernameImage}`
      }
      alt={username}
    />
  ) : (
    <div
      className={`rounded-full border-2 border-third p-2 w-full h-full flex justify-center items-center text-xl ${
        isExpired ? "bg-green-500 text-white" : "bg-main text-acent"
      }`}
    >
      {username?.charAt(0)}
    </div>
  );

  return (
    <div
      ref={platformRef}
      onClick={isDisabled ? () => {} : onClick}
      onMouseEnter={() => setShowTooltip(true)}
      onMouseLeave={() => setShowTooltip(false)}
      className={`group relative w-[50px] h-[50px] md:w-[60px] min-w-[50px] min-h-[50px] md:h-[60px] rounded-full border-2 flex justify-center items-center focus:outline-none ${borderColor} ${
        isDisabled ? "cursor-not-allowed filter grayscale" : "cursor-pointer"
      }`}
    >
      {icon}
      {usernameDisplay}
      <PlatformIcon
        platform={platform}
        className="absolute -bottom-2 -right-2 w-6 h-6 md:w-8 md:h-8 rounded-full"
      />
      {username && showTooltip && (
        <Tooltip
          text={`${displayName || ""} (${username})`}
          parentRef={platformRef}
        />
      )}
    </div>
  );
};

export const EmptyPlatform = ({ platform, onClick = () => {} }) => {
  return (
    <div
      onClick={onClick}
      className="relative w-[50px] h-[50px] min-w-[50px] min-h-[50px] md:min-w-[60px] md:min-h-[50px] md:w-[60px] md:h-[60px] rounded-full border-2 border-gray-200 flex justify-center items-center cursor-pointer"
    >
      <Plus className="w-5 h-5" />
      {platform ? (
        <PlatformIcon
          platform={platform}
          className="absolute -bottom-2 -right-2 w-6 h-6 md:w-8 md:h-8 rounded-full"
        />
      ) : null}
    </div>
  );
};
